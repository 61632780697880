<template>
  <a-layout v-if="project == null">
    <a-modal
      okText="确定"
      @ok="outext"
      :closable="false"
      :maskClosable="false"
      :visible="logvisible"
      :cancel-button-props="{ props: { disabled: true } }"
    >
      登录已失效,请前往重新登陆
    </a-modal>
  </a-layout>
  <a-layout v-else id="components-layout-demo-responsive">
    <a-layout-sider breakpoint="lg" collapsed-width="0" width="250">
      <vue-scroll :ops="ops">
        <div class="logo">
          <img style="width: 75%" src="../../assets/logo.png" alt="" />
        </div>
        <div
          style="
            text-align: center;
            margin-top: -10px;
            margin-bottom: 15px;
            color: #e9e9e9;
          "
        >
          {{ infos.name }}
        </div>
        <!-- 路由 -->
        <a-menu
          theme="dark"
          mode="inline"
          :open-keys="openKeys"
          :selected-keys="selectedKeys"
          @openChange="onOpenChange"
          :style="{ height: '100%', borderRight: 0 }"
          @select="onChange"
          v-for="item in project.modules"
          :key="item.path"
        >
          <!-- //动态路由 -->
          <a-menu-item
            @click="onyanz"
            v-if="item.children == ''"
            :key="item.path"
          >
            <router-link :to="{ path: item.path }">
              <a-icon :type="item.icon" />
              {{ item.name }}
            </router-link>
          </a-menu-item>
          <a-sub-menu :key="item.path" v-else>
            <span slot="title">
              <a-icon :type="item.icon" />
              {{ item.name }}</span
            >
            <a-menu-item
              @click="onyanz"
              v-for="item in item.children"
              :key="item.path"
            >
              <router-link :to="{ path: item.path }">
                <a-icon :type="item.icon" />
                {{ item.name }}
              </router-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </vue-scroll>
    </a-layout-sider>
    <a-layout style="position: relative">
      <a-layout-header
        class="layout-header"
        style="
          background-color: #fff;
          border-bottom: 1px solid #eaeaea;
          padding: 0;
        "
      >
        <div class="personal">
          <div>
            <div class="wel-index" v-if="project.projectInfo == null">
              尚未添加项目
            </div>

            <div v-else class="wel-index flow-d">
              <span>{{
                project.projectInfo.projectName == ''
                  ? ''
                  : project.projectInfo.projectName
              }}</span>
              <a-dropdown :trigger="['click']">
                <span
                  @click="e => e.preventDefault()"
                  style="
                    color: #739fca;
                    font-weight: 600;
                    font-size: 15px;
                    margin-left: 20px;
                  "
                  >切换项目<a-icon type="right"
                /></span>
                <a-menu slot="overlay">
                  <a-menu-item
                    @click="onon"
                    v-for="item in projectlists"
                    :key="item"
                  >
                    <a>{{ item.name }}</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
          <div class="flow-d-row-c">
            <a-popover trigger="click ">
              <template slot="content">
                <div
                  style="
                    border-bottom: 1px solid #d9d9d9;
                    height: 23px;
                    margin-bottom: 8px;
                  "
                >
                  <a @click="onread" style="float: right" href="javascript:;"
                    >全部标记已读</a
                  >
                </div>
                <div
                  v-if="messagelist.length > 0"
                  class="message"
                  style="width: 300px"
                >
                  <div
                    v-for="item in messagelist"
                    :key="item.id"
                    class="flow-d"
                  >
                    <div style="margin-right: -5px">
                      <img
                        v-if="item.senderId == '1'"
                        style="width: 30px; height: 30px; border-radius: 50%"
                        src="../../assets/prove.png"
                        alt=""
                      />
                      <img v-else :src="item.isNotify" alt="" />
                    </div>
                    <div style="flex: 1; padding-left: 15px">
                      <div
                        style="
                          justify-content: space-between;
                          color: #d9d9d9;
                          height: 35px;
                        "
                        class="flow-d-row-c"
                      >
                        <span>{{ item.senderName }}</span>
                        <span
                          >{{ item.sysSendTime | time }}
                          {{ item.sysSendTime | time1 }}</span
                        >
                      </div>
                      <div style="font-weight: 600; font-size: 15px">
                        {{ item.process }}
                      </div>

                      <div>
                        <a-tooltip>
                          <template slot="title">
                            {{ item.content }}
                          </template>
                          {{ item.content.substring(0, 20) + '···' }}
                        </a-tooltip>
                      </div>
                      <div
                        style="justify-content: space-between"
                        class="flow-d-row-c"
                      >
                        <div style="color: #999">{{ item.title }}</div>
                        <a @click="onrouter(item)" href="javascript:;"
                          >查看详情</a
                        >
                        <!-- <router-link
                          @click="onrouter"
                          :to="{ path: item.linkUrl }"
                        >
                          <a href="javascript:;">查看详情</a>
                        </router-link> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 100%; text-align: center" v-else>
                  暂无数据
                </div>
              </template>
              <template slot="title"> </template>
              <a-badge style="margin-right: 40px" :count="messagelist.length">
                <a-icon style="font-size: 20px" type="bell" />
              </a-badge>
            </a-popover>
            <a-popover
              v-model="visibleuser"
              placement="bottomRight"
              trigger="click"
            >
              <template slot="content">
                <div style="width: 270px">
                  <div style="padding-left: 10px" class="flow-d-row-c">
                    <img
                      v-if="this.logo == null"
                      style="
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                      "
                      src="../../assets/logo.svg"
                      alt=""
                    />
                    <img
                      style="
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                      "
                      v-else
                      :src="logo"
                      alt=""
                    />
                    <span style="font-size: 17px; color: #333">{{
                      project.name
                    }}</span>
                    <span
                      @click="onMine"
                      style="
                        position: absolute;
                        right: 53px;
                        cursor: pointer;
                        color: #999;
                      "
                    >
                      <a-icon type="edit" />
                      个人设置
                    </span>
                  </div>
                  <a-form-model
                    style="padding-left: 10px"
                    class="login-user"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ spam: 10 }"
                  >
                    <a-form-model-item label="手机号">
                      <span>{{ mobile == null ? '暂无' : mobile }}</span>
                    </a-form-model-item>
                    <a-form-model-item label="邮箱">
                      <span>{{ email == null ? '暂无' : email }}</span>
                    </a-form-model-item>
                    <a-form-model-item label="微信">
                      <span>暂无</span>
                    </a-form-model-item>
                  </a-form-model>
                  <div style="height: 40px" class="flow-d">
                    <div
                      class="flow-d-row-c"
                      @click="onpassword"
                      style="flex: 1; justify-content: center; cursor: pointer"
                    >
                      <a-icon
                        style="margin-right: 6px; font-size: 22px"
                        type="lock"
                      />
                      <span>修改密码</span>
                    </div>
                    <div
                      class="flow-d-row-c"
                      @click="onout"
                      style="
                        flex: 1;
                        justify-content: center;
                        color: #af6569;
                        cursor: pointer;
                      "
                    >
                      <a-icon
                        style="
                          margin-right: 6px;
                          font-size: 22px;
                          color: #af6569;
                        "
                        type="import"
                      />
                      <span>退出登录</span>
                    </div>
                  </div>
                </div>
              </template>
              <template slot="title"> </template>
              <div
                style="justify-content: center; align-items: center"
                class="flow-d"
              >
                <img
                  v-if="this.logo != null"
                  style="
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 3px;
                  "
                  :src="logo"
                  alt=""
                />
                <img
                  style="
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-right: 5px;
                  "
                  v-else
                  src="../../assets/logo.svg"
                  alt=""
                />
                <a> {{ project.name }} <a-icon type="down" /> </a>
              </div>
            </a-popover>
          </div>

          <!-- <a-menu-item @click="onMine" key="1"> 个人中心 </a-menu-item>
              <a-menu-item key="2"> 安全中心 </a-menu-item> -->
          <!-- <a-menu-item @click="onout" key="3"> 注销 </a-menu-item> -->
        </div>
      </a-layout-header>
      <a-layout-content style="display: flex">
        <router-view> </router-view>

        <a-modal
          okText="确定"
          @ok="outext"
          :closable="false"
          :maskClosable="false"
          :visible="logvisible"
          :cancel-button-props="{ props: { disabled: true } }"
        >
          登录已失效,请前往重新登陆!!
        </a-modal>
        <a-modal
          okText="确定"
          @ok="onresetf"
          :closable="false"
          :maskClosable="false"
          :visible="visibles"
          :cancel-button-props="{ props: { disabled: true } }"
          title="修改密码"
        >
          <a-alert message="首次登陆，请修改密码" banner />
          <a-form-model-item style="margin-bottom: 3px" label="密码">
            <a-input
              @blur="getsecuritycheck"
              placeholder="请输入密码"
              v-model="form.password"
            />
          </a-form-model-item>
          <div class="info_pass">
            <p>
              安全级别：<strong :class="strength > 0 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 1 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 2 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 3 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 4 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 5 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 6 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 7 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 8 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 9 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 10 ? 'ss' : 's1'">■</strong>
              <strong :class="strength > 11 ? 'ss' : 's1'">■</strong>
              <span style="margin-left: 6px">
                {{
                  level == 'EASY'
                    ? '简单'
                    : level == 'MIDIUM'
                    ? '中等'
                    : level == 'STRONG'
                    ? '强大'
                    : level == 'VERY_STRONG'
                    ? '很强'
                    : '极强'
                }}</span
              >
            </p>
          </div>
          <a-form-model-item label="确认密码">
            <a-input placeholder="请输入确认密码" v-model="form.password2" />
          </a-form-model-item>
        </a-modal>
        <a-modal
          okText="确定"
          @ok="getout"
          :closable="false"
          :maskClosable="false"
          :visible="visibless"
          :cancel-button-props="{ props: { disabled: true } }"
        >
          修改密码成功，请重新登陆
        </a-modal>
        <a-modal
          okText="确定"
          @ok="queding"
          @cancel="quxiao"
          :closable="false"
          :maskClosable="false"
          :visible="provisible"
        >
          确定要将当前项目切换至{{ projectname }}么
        </a-modal>
      </a-layout-content>
      <a-layout-footer style="textalign: center">
        {{ infos.shortName }} &nbsp;&nbsp;{{ infos.icp }} &nbsp;&nbsp;{{
          infos.police == '' ? '' : infos.police
        }}
      </a-layout-footer>
      <!-- <a-layout-footer  style="textAlign: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>
<script>
// function getOpenKeys() {
//   let openKeys = localStorage.getItem("openKeys");
//   return openKeys ? [openKeys] : [];
// }

// function getSelectedKeys() {
//   let selectedKeys = localStorage.getItem("selectedKeys");
//   return selectedKeys ? [selectedKeys] : [];
// }
import '../../assets/font-awesome-4.7.0/css/font-awesome.css'
import http from '../../http'
const OSS = require('ali-oss')
let client
export default {
  inject: ['reload'],
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: true, //是否只有滚动的时候才显示滚动条
          background: '#999', //滚动条颜色
          opacity: 0.5, //滚动条透明度
          'overflow-x': 'hidden',
        },
      },
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      openKeys: this.$store.state.openKeys,
      selectedKeys: this.$store.state.selectedKeys,
      project: [],
      messagelist: [],
      mobile: '',
      logo: null,
      index: '',
      email: '',
      collapsed: false,
      visible: false,
      visibleuser: false,
      logvisible: false,
      visibles: false,
      visibless: false,
      provisible: false,
      //   projectlist: this.$store.state.projects,
      value: '',
      key: '',
      list: [],
      projectname: '',
      infos: {},
      strength: 5,
      level: 'EASY',
      form: {
        password: '',
        password2: '',
      },
      userlist: [],
    }
  },

  filters: {
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        const h = times[0].split('-')
        return h[1] + '-' + h[2]
      }
    },
    time1(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[1]
      }
    },
  },
  computed: {
    projectlists() {
      return this.$store.state.projects
    },
  },
  methods: {
    async getsecuritycheck(e) {
      try {
        const res = await http.getsecuritycheck(e.target._value)
        const { success, data } = res.data
        if (success) {
          if (data.strength < 4) {
            this.$message.error('密码安全等级较低!')
          }
          this.strength = data.strength
          this.level = data.level
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onOpenChange(openKeys) {
      //   console.log(openKeys);
      const latestOpenKey = openKeys.find(
        key => this.openKeys.indexOf(key) === -1
      )
      if (latestOpenKey) {
        this.openKeys = [latestOpenKey]
        localStorage.setItem('openKeys', latestOpenKey)
      } else {
        this.openKeys = []
        localStorage.setItem('openKeys', [])
      }
    },
    queding() {
      this.provisible = false
      if (this.project.projectInfo.projectName == this.projectname) {
        this.$message.error(`你已处于${this.projectname}中,无法切换`)
        return
      } else {
        this.postthisproject(this.key)
        if (this.selectedKeys[0] == '/welcome') {
          this.reload()
          return
        }

        this.$router.push({ name: 'welcome' })
        this.selectedKeys = ['/welcome']
        this.openKeys = ['/welcome']
      }
    },
    onyanz() {
      this.getyanz()
    },
    quxiao() {
      this.provisible = false
    },
    onon(e) {
      this.projectname = e.key.name
      this.key = e.key.id
      this.provisible = true
    },
    onChange(value) {
      this.selectedKeys = [value.key]
      //   console.log(this.selectedKeys);
      localStorage.setItem('selectedKeys', this.selectedKeys)
    },
    handleMenuClick(e) {
      if (e.key === '3') {
        this.visible = false
      }
    },
    onresetf() {
      if (this.strength < 4) {
        this.$message.error('安全等级过低,无法提交!')
        return
      }
      this.securityreset(this.form)
    },
    onMine() {
      this.visibleuser = false
      this.$router.push({ name: 'mine' })
    },
    out() {
      this.visibleuser = false
      this.$router.replace({ path: '/login' })
      if (JSON.parse(localStorage.getItem('store-userlist')) == null) {
        this.logvisible = true
      }
    },
    outext() {
      this.logvisible = false
      this.$router.replace({ path: '/login' })
    },
    onout() {
      this.visibleuser = false
      if (JSON.parse(localStorage.getItem('store-userlist')) == null) {
        this.logvisible = true
      } else {
        this.getout()
      }
    },
    async getprojectInfoall() {
      try {
        const res = await http.getprojectInfoall()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.infos = data.info
          this.$store.state.infos = data.info
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async securityreset(form) {
      try {
        const res = await http.securityreset(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('重置成功')
          this.visibless = true
          this.visibles = false
        } else {
          this.$message.error(msg)
          return
        }
        // console.log(res);
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onrouter(e) {
      const id = []
      id.push(e.id)
      this.getmarkSysRead(id)
      this.$router.push({ path: e.linkUrl })
    },
    async getyanz() {
      try {
        const res = await http.getyanz()
        const that = this
        // console.log("status", res);
        if (res.status == 200) {
          //   console.log("status", res);
        } else {
          that.logvisible = true
          //   console.log(this.logvisible);
        }
      } catch (ex) {
        this.logvisible = true
      }
    },
    async getuserthing() {
      try {
        const res = await http.getuserthing()
        const { success, data } = res.data
        if (success) {
          //   console.log(data.user);
          if (data.user.avatar && data.user.avatar.substr(0, 1) != '[') {
            return
          }
          const avatar = JSON.parse(data.user.avatar)
          localStorage.setItem('store-userlist', JSON.stringify(data.user))
          if (avatar && avatar.length > 0) {
            avatar.forEach(item => {
              const url = client.signatureUrl(item.name)
              this.logo = url
            })
          }
          this.project = data.user
        }
      } catch (ex) {
        console.log('error', ex)
      }
    },
    async getout() {
      try {
        const res = await http.getlogout()
        const { success } = res.data
        if (success) {
          this.$message.success('注销成功')
          this.$router.replace({ path: '/login' })
        }
        // console.log(res);
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onpassword() {
      this.visibleuser = false
      this.$router.push({ name: 'password' })
    },
    async getprojectlist() {
      try {
        const res = await http.getprojectlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data.projects);
          this.$nextTick(function () {
            this.$store.state.projects = data.projects
          })
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getuser() {
      try {
        const res = await http.getuser()
        const { success, data } = res.data
        if (success) {
          this.mobile = data.user.mobile
          this.email = data.user.email
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
        this.getuserthing()
      } catch (e) {
        console.error(e)
      }
    },
    async postthisproject(projectId) {
      try {
        const res = await http.postthisproject(projectId)
        const { success, msg } = res.data
        if (success) {
          this.getuserthing()
          this.$message.success(msg)
        } else {
          this.$message.error(msg)
        }
      } catch (ex) {
        this.$message.error('操作失败')
        console.log('error:' + ex)
      }
    },
    async getwelcomemessage() {
      try {
        const res = await http.getwelcomemessage()
        const { success, data } = res.data
        if (success) {
          this.messagelist = data.messages
          this.messagelist.forEach((item, index) => {
            if (item.senderName == '系统') {
              item.isNotify = '../../assets/prove.png'
            } else {
              setTimeout(() => {
                this.getuserinfo(item.senderId)
              }, 100)
              this.index = index
            }
          })
          //   console.log(this.messagelist);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getuserinfo(id) {
      try {
        const res = await http.getuserinfo(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          JSON.parse(data.userinfo.avatar).forEach(item => {
            if (item.name != null) {
              const url = client.signatureUrl(item.name)
              //   console.log(url);
              this.messagelist[this.index].isNotify = url
            }
          })
          //   console.log(this.userinfolist);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onread() {
      const id = []
      this.messagelist.forEach(item => {
        id.push(item.id)
      })
      this.getmarkSysRead(id)
    },
    async getmarkSysRead(id) {
      try {
        const res = await http.getmarkSysRead(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.getwelcomemessage()
          //   console.log(data);
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    popstate() {
      const Url = window.location.href.split('#')
      const url = Url[1]
      const path = url.split('/')
      localStorage.setItem('openKeys', ['/' + path[1]])
      localStorage.setItem('selectedKeys', [url])
      this.reload()
    },
  },
  created() {
    // 创建vm实例后执行
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener('popstate', this.popstate, false)
  },
  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.popstate, false)
  },
  mounted() {
    this.getuser()
    this.getyanz()
    this.getsts()
    this.getprojectlist()

    this.getwelcomemessage()
    this.getprojectInfoall()
    this.project = JSON.parse(localStorage.getItem('store-userlist'))
    // console.log(this.project);
    if (this.project.state == -1) {
      this.visibles = true
    } else {
      console.log()
    }
    if (this.project == null) {
      this.logvisible = true
    }
    this.openKeys = [localStorage.getItem('openKeys')]
    this.selectedKeys = [localStorage.getItem('selectedKeys')]
    // console.log(this.openKeys, this.selectedKeys);
  },
}
</script>

<style lang="less">
#components-layout-demo-responsive {
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
}
.logo {
  text-align: center;
  padding: 20px 33px;
}
.personal {
  width: 100%;
  padding: 0 20px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ss {
  color: #f60;
}
.flow-d {
  display: flex;
  flex-direction: row;
}
.message {
  max-height: 400px;
  overflow: auto;
  padding-right: 10px;
}
.flow-d-row-c {
  display: flex;
  flex-direction: row;
  align-items: center;
}
//黑色主体
.ant-layout-sider-children,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #fff;
  background-color: #00142c;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none;
  background-color: #00142c;
}
/* .ant-menu-dark.ant-menu-inline */
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: #fff;
}
//橙色
// .ant-menu-item-selected > a,
// .ant-menu-item-selected > a:hover {
//   color: #dd6419;
// }
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: #fff5e8;
// }
.ant-layout-sider {
  overflow-y: auto;
  overflow-x: hidden;
}
/* .ant-layout {
  height: 100%;
} */
.ant-dropdown-link {
  color: #000;
}

#reg .info_pass {
  width: 290px;
  height: 34px;
  padding: 4px 0 0 16px;
  top: 5px;
  left: 295px;
  letter-spacing: 0;
  display: none;
}
#reg .info_pass p {
  height: 10px;
  line-height: 10px;
  color: #666;
}
#reg .info_pass p strong.s {
  color: #ccc;
}
.ant-input {
  padding-left: 30px;
}
::v-deep.__bar-is-vertical {
  right: -1px !important;
}
::v-deep.__bar-is-horizontal {
  display: none !important;
}
.ant-menu-submenu-title {
  font-weight: 700;
}
.layout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wel-index {
  color: #000;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wel-index:hover {
  cursor: pointer;
  /* background-color: #e6f7ff; */
}
.ant-layout-content {
  min-height: max-content;
}
.wel-index > span {
  font-weight: 700;
}
.project-left,
.project-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.project-left-img img {
  height: 40px;
}
::v-deep
  .ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background-image: none;
}
.ant-layout-footer {
  padding: 15px 0;
}
.s1 {
  //   background-color: #666;
  color: #999;
}
.Nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.tag-Nav {
  cursor: pointer;
  width: 130px;
  height: 34px;
  background-color: #ecf0f1;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.tag-Nav-hov {
  cursor: pointer;
  width: 130px;
  height: 34px;
  color: #1890ff;
  background-color: #ecf0f1;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Nav-close {
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
}
.ant-badge-count {
  height: 14px;
  line-height: 14px;
}
.login-user .ant-form-item {
  margin-bottom: 0;
  .ant-form-item-children {
    color: #333;
  }
  .ant-form-item-label > label {
    color: #999;
  }
  .ant-form-item-label {
    text-align: left;
  }
}
.foot {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 250px;
}
.ant-table-column-title {
  font-weight: 600;
}
.ant-row .ant-form-item label {
  font-weight: 600;
}
.ant-tabs-nav .ant-tabs-tab {
  font-weight: 600;
}
</style>
